import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { isObservable, Observable, of } from 'rxjs';
import { Entity } from '@core/enums/entity.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@capturum/api';
import { LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { EntityConfig } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IEntityBase } from '@core/interfaces/entity-base';

@Component({
  template: '',
})
export class DxpBaseEntityComponent<EntityModel extends IEntityBase> extends DestroyBase {
  public routes: typeof AppRoutes = AppRoutes;
  public Entity: typeof Entity = Entity;
  public entity$: Observable<EntityModel>;
  public entity: EntityModel;
  public additionalResources: any;
  public additionalResources$: Observable<any>;

  protected entityConfig: EntityConfig;
  protected pageConfig: Partial<LayoutConfig>;
  protected layoutConfig: LayoutConfig;

  // Injectable dependencies:
  protected router = inject(Router);
  protected apiService = inject(ApiService<EntityModel>);
  protected notificationService = inject(NotificationService);
  protected translateService = inject(TranslateService);
  protected layoutConfigService = inject(LayoutConfigService);

  constructor(protected route: ActivatedRoute) {
    super();
  }

  /***
   * This method functions as a hook for components where the defaultConfig is depending on other requests
   * or logic to be run first. Example given: Address & Contact forms need to get Organisation name before
   * generating the defaultConfig subtitle.
   *
   * @returns Observable<any>
   */
  protected getAdditionalResources(entity?: EntityModel): Observable<any> {
    // This method serves purely as a hook

    return of(null);
  }

  /***
   * This method is used when editing/copying a new Entity. It runs 2 hooks (getAdditionalResources() &
   * overWriteDefaultConfig()) before setting the config and thus finishing the process of loading the page. The
   * getAdditionalResources() hook is executed before generating the defaultConfig, and the overwriteDefaultConfig()
   * hook is executed before setting the final config.
   *
   * @returns Observable<EntityModel>
   */
  protected getConfig(): void {
    this.entity$ = this.getEntityResource();

    this.entity$
      .pipe(
        map((entity) => {
          this.entity = entity;

          return entity;
        }),
        switchMap((entity) => {
          this.additionalResources$ = entity ? this.getAdditionalResources(entity) : this.getAdditionalResources();

          return this.additionalResources$;
        }),
        map((additionalResources: any) => {
          this.additionalResources = additionalResources;

          return additionalResources;
        }),
        switchMap((additionalResources) => {
          const overwrittenResource = additionalResources
            ? this.overwriteResourceUpdated(this.entity, additionalResources)
            : this.overwriteResourceUpdated(this.entity);

          return isObservable(overwrittenResource) ? overwrittenResource : of(null);
        }),
        switchMap((overwrittenResource: any) => {
          return overwrittenResource
            ? this.generateDefaultConfig(this.entity, overwrittenResource)
            : this.generateDefaultConfig(this.entity);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((config: LayoutConfig) => {
        const updatedConfig = this.overwriteDefaultConfig(this.entity, config);

        this.layoutConfig = updatedConfig;
        this.layoutConfigService.addConfig(updatedConfig);
      });
  }

  /***
   * This method functions as a hook for when you want to completely bypass the default way an entity is
   * requested.
   *
   * @returns Observable<EntityModel>
   */
  protected getEntityResource(): Observable<EntityModel> {
    this.entity$ = this.overwriteEntityResource();

    return this.entity$;
  }

  /***
   * This method requests the entity data needed to fill the form for Edit/Copy pages. If you
   * decide to overwrite this implementation, please make sure to set this.entity$ and this.entity.
   *
   * @returns Observable<EntityModel>
   */
  protected overwriteEntityResource(): Observable<any> {
    return this.apiService.get(this.entityConfig.id, this.entityConfig.apiOptions).pipe(
      tap((entity: EntityModel) => {
        this.entity = entity;
      }),
      shareReplay(),
      takeUntil(this.destroy$),
    );
  }

  /***
   * This method functions as a hook after a request for the entity was completed. If it returns an Observable
   * this value it wraps will be waited on (synchronous) and sent to the next hook: generateDefaultConfig().
   * It doesn't need to return a value (asynchronous).
   *
   * @param entity The request data of the entity we requested
   * @param additionalResourcesValues The observed value of getAdditionalResources()
   *
   * @returns void | Observable<any>
   */
  protected overwriteResourceUpdated(entity: EntityModel, additionalResourcesValues?: any): void | Observable<any> {
    // This method serves purely as a hook
  }

  /***
   * This method functions as a hook to set the new config.
   *
   * @param entity The request data of the entity we requested
   * @param overwrittenResourceValue The returned value if overWriteResourceUpdated() returned a new Observable
   *
   * @returns void | Observable<any>
   */
  protected generateDefaultConfig(entity?: EntityModel, overwrittenResourceValue?: any): Observable<LayoutConfig> {
    return this.layoutConfigService.generateDefaultConfig(this.entityConfig);
  }

  /***
   * This method functions as a hook to overwrite the default generated config.
   *
   * @param entity The request data of the entity we requested
   * @param defaultConfig The returned default config of generateDefaultConfig()
   *
   * @returns void | Observable<any>
   */
  protected overwriteDefaultConfig(entity: EntityModel, defaultConfig: LayoutConfig): LayoutConfig {
    return defaultConfig;
  }
}
